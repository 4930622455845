import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { Spinner } from "reactstrap";
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseApp } from "helpers/firebase_helper";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin } from "../../store/actions";

import logoImg from "assets/images/logo-dark.png";

const Login = () => {

  const auth = getAuth(getFirebaseApp());
  const [user, loading] = useAuthState(auth);

  const [passwordShow, setPasswordShow] = useState(false);
  const [referralCode, setReferralCode] = useState("");

  //meta title
  document.title = `Login | Vertex Robot Trading${process.env.REACT_APP_ENV != 'PROD' ? ' (' + process.env.REACT_APP_ENV + ')' : ''}`;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, navigate, location));
    }
  });


  const selectLoginState = (state) => state.login;

  const LoginProperties = createSelector(
      selectLoginState,
        (login) => ({
          errorMessage: login.error          
        })
  );

  const {
      errorMessage
  } = useSelector(LoginProperties);

  const signIn = (type: string) => {
      dispatch(socialLogin(type, navigate, location));
  };

  //for facebook and google authentication
  const socialResponse = (type: string) => {
    signIn(type);
  };

  useEffect(() => {
    setReferralCode(localStorage.getItem("affiliateRef"));
  }, []);

  if (loading) {
    return <Spinner/>
  }
  
  if (user) {
    return (
      <Navigate to={{ pathname: "/" }} />
    );
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="banner-top">
                  <Row>
                    <Col className="col-7">
                      <div className="p-4">
                        <h4>Vertex Robot Trading</h4>
                        <p>Sign in to continue to Vertex Forex Robot Trading.</p>
                        {referralCode && <p className="text-success">Referral code: {referralCode}</p>}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logoImg} alt="" className="p-4 img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}

                      <div className="mt-3 mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={passwordShow ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          {/* <li className="list-inline-item">
                          <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("facebook");
                              }}
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li> */}
                          {/*<li className="list-inline-item">*/}
                          {/*  <TwitterLogin*/}
                          {/*    loginUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                          {/*    }*/}
                          {/*    onSuccess={this.twitterResponse}*/}
                          {/*    onFailure={this.onFailure}*/}
                          {/*    requestTokenUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                          {/*    }*/}
                          {/*    showIcon={false}*/}
                          {/*    tag={"div"}*/}
                          {/*  >*/}
                          {/*    <a*/}
                          {/*      href=""*/}
                          {/*      className="social-list-item bg-info text-white border-info"*/}
                          {/*    >*/}
                          {/*      <i className="mdi mdi-twitter"/>*/}
                          {/*    </a>*/}
                          {/*  </TwitterLogin>*/}
                          {/*</li>*/}
                          <li className="list-inline-item">
                          <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("google");
                              }}
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 text-center">
                  Don&#39;t have an account?
                <Link to="/register" className="text-primary">
                    <h3>Register now</h3>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="footer" style={{left: 0}}>
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Vertex Robot Trading</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">

              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Login;

Login.propTypes = {
  navigate: PropTypes.object,
};
