import { useEffect } from "react";
import PropTypes from "prop-types";
import { logoutUser } from "../../store/actions";

//redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser(navigate));
  }, [dispatch, navigate]);

  return <></>;
};

Logout.propTypes = {
  navigate: PropTypes.object,
};

export default Logout;