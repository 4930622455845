import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, Input, Label, Spinner } from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link, useParams } from "react-router-dom";


// import images
import logoImg from "assets/images/logo-dark.png";
import { getSub, updateSub } from "store/actions";

const UnsubPage = () => {

  //meta title
  document.title = "Unsubscribe | Vertex Robot Trading";

  const SUBS = [
    {label: "Sales email notifications", id: "sales", type: "email"},
    {label: "Support email notifications", id: "support", type: "email"},
    // {label: "Robot email notifications", id: "robot", type: "email"},
    {label: "Results email notifications", id: "results", type: "email"},
    {label: "Unsubscribe from all email notifications", id: "all", type: "email"}
  ];

  const dispatch = useDispatch();
  const { userId } = useParams();

  // const { currentUser } = useSelector(createSelector((state: any) => state.login, (login: any) => ({currentUser: login.user})));
  const { subs, loadingSubs, error, success } = useSelector(createSelector((state: any) => state.profile, 
      (profile: any) => ({
        subs: profile.subs, 
        loadingSubs: profile.loading, 
        error: profile.error,
        success: profile.success
      })));


  const [unSubsChecked, setUnSubsChecked] = useState({});
  const [email, setEmail] = useState("");

  useEffect(() => {
    dispatch(getSub(userId))
  }, []);

  useEffect(() => {
    if (!subs) {
      return;
    }
    setEmail(subs.email);
    const subsChecked = {};
    for (let id of SUBS.map(sub => sub.id)) {
      if (id == "all") {
        subsChecked[id] = (subs.unsubs?.indexOf(id) >= 0) || false;
      } else {
        subsChecked[id] = !(subs.unsubs?.indexOf(id) >= 0)
      }
    }
    setUnSubsChecked(subsChecked);
  }, [subs]);

  const handleSubChange = (id: string) => {
    unSubsChecked[id] = !unSubsChecked[id];
    setUnSubsChecked({...unSubsChecked});
  }

  const handleSubmit = () => {
    const unsubs = [];
    if (unSubsChecked["all"]) {
      unsubs.push("all");
    } else {
      for (let key in unSubsChecked) {
        if (key != "all" && !unSubsChecked[key]) {
          unsubs.push(key);
        }
      }
    }
    dispatch(updateSub(userId, unsubs))
  }   

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="banner-top">
                  <Row>
                    <Col className="col-7">
                      <div className="p-4">
                        <h4>Unsubscribe</h4>
                        <p>
                        Please adjust your email preferences below
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logoImg} alt="" className="p-4 img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div className="p-2">
                    {error && 
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>}
                    {success && 
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {success}
                      </Alert>}

                    <div className="mt-3 mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Loading..."
                        value={email}
                        readOnly={true}/>
                      
                    </div>
                    
                    { SUBS.map(sub => 
                      <Row key={`sub-${sub.id}`} className="form-switch form-switch-lg py-2">
                          {sub.id == "all" && <hr/>}
                          <label className={`col-form-label col-10 xcol-xl-11 ${unSubsChecked["all"] && "text-muted"}`}
                            htmlFor={sub.id}>
                            {sub.label}
                          </label>    
                          <input
                            type="checkbox"
                            className="form-check-input ms-1 col-2"
                            id={sub.id}
                            checked={unSubsChecked[sub.id] || false}
                            onChange={() => { handleSubChange(sub.id) }}
                            disabled={sub.id == "all" ? false : unSubsChecked["all"]}
                          />

                      </Row>
                    )}

                    <Row className="my-3">
                      <Col className="text-end">
                        <button
                          className="btn btn-primary w-md "
                          onClick={handleSubmit}
                          disabled={loadingSubs}>
                          Update
                        </button>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go to{" "}
                  <Link to="/" className="font-weight-medium text-primary">
                    Dashboard
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default UnsubPage;
