import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { GET_RESULTS } from "./actionTypes"
import { getResultsSuccess, getResultsError } from "./actions"
import { getResults } from "helpers/results_api"


function* onGetResults() {
  try {
    const response = yield call(getResults)
    yield put(getResultsSuccess(response))      
  } catch (error) {
    yield put(getResultsError(error))
  }
}

function* resultsSaga() {
  yield takeEvery(GET_RESULTS, onGetResults);
}

export default resultsSaga
