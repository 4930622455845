import { 
  GET_RESULTS_SUCCESS,
  GET_RESULTS,
  GET_RESULTS_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  success: false,
  loading: true,
  results: [],
};

const results = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESULTS:
      state = { ...state, loading: true, error: "" };
      break;
    case GET_RESULTS_SUCCESS:
      state = { ...state, results: action.payload, loading: false };
      break;
    case GET_RESULTS_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
  }
  return state;
};

export default results;
