import {
  GET_CHATS,
  GET_CHATS_FAIL,
  GET_CHATS_SUCCESS,
  GET_CONTACTS,
  GET_CONTACTS_FAIL,
  GET_CONTACTS_SUCCESS,
  SELECT_CHANNEL,
  GET_MESSAGES,
  GET_MESSAGES_FAIL,
  GET_MESSAGES_SUCCESS,
  ADD_MESSAGE,
  ADD_MESSAGE_FAIL,
  ADD_MESSAGE_SUCCESS,
  DELETE_MESSAGE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAIL,
  RECIEVE_MESSAGE,
  RECIEVE_MESSAGE_SUCCESS,
  SELECT_CHANNEL_SUCCESS,
  RECIEVED_USER_STATUS_SUCCESS,
  RECIEVED_USER_STATUS,
  RESET_NEW_COUNT,
  RESET_NEW_COUNT_SUCCESS,
  RESET_NEW_COUNT_FAIL,
  IS_CHAT_OPEN
} from "./actionTypes"

export const getChats = () => ({
  type: GET_CHATS,
})

export const getChatsSuccess = chats => ({
  type: GET_CHATS_SUCCESS,
  payload: chats,
})

export const getChatsFail = error => ({
  type: GET_CHATS_FAIL,
  payload: error,
})

export const selectChannel = (channel) => ({
  type: SELECT_CHANNEL,
  payload: channel
})

export const selectChannelSuccess = channel => ({
  type: SELECT_CHANNEL_SUCCESS,
  payload: channel,
})

export const isChatOpen = payload => ({
  type: IS_CHAT_OPEN,
  payload,
})

export const getContact = (memberId) => ({
  type: GET_CONTACTS,
  payload: {memberId}
})

export const getContacts = () => ({
  type: GET_CONTACTS,
})

export const getContactsSuccess = (contacts, user) => ({
  type: GET_CONTACTS_SUCCESS,
  payload: contacts,
  user
})

export const getContactsFail = error => ({
  type: GET_CONTACTS_FAIL,
  payload: error,
})

export const recieveUserStatus = data => ({
  type: RECIEVED_USER_STATUS,
  payload: data,
})

export const recieveUserStatusSuccess = (data, user) => ({
  type: RECIEVED_USER_STATUS_SUCCESS,
  payload: data,
  user
})

export const getMessages = channelId => ({
  type: GET_MESSAGES,
  channelId,
})

export const getMessagesSuccess = messages => ({
  type: GET_MESSAGES_SUCCESS,
  payload: messages,
})

export const getMessagesFail = error => ({
  type: GET_MESSAGES_FAIL,
  payload: error,
})

export const addMessage = message => ({
  type: ADD_MESSAGE,
  payload: message,
})

export const addMessageSuccess = message => ({
  type: ADD_MESSAGE_SUCCESS,
  payload: message,
})

export const addMessageFail = error => ({
  type: ADD_MESSAGE_FAIL,
  payload: error,
})

export const resetNewCount = channelId => ({
  type: RESET_NEW_COUNT,
  payload: channelId,
})

export const resetNewCountSuccess = data => ({
  type: RESET_NEW_COUNT_SUCCESS,
  payload: data,
})

export const resetNewCountFail = error => ({
  type: RESET_NEW_COUNT_FAIL,
  payload: error,
})

export const recieveMessage = message => ({
  type: RECIEVE_MESSAGE,
  payload: message,
})

export const recieveMessageSuccess = message => ({
  type: RECIEVE_MESSAGE_SUCCESS,
  payload: message,
})

export const deleteMessage = data => ({
  type: DELETE_MESSAGE,
  payload: data,
})

export const deleteMessageSuccess = data => ({
  type: DELETE_MESSAGE_SUCCESS,
  payload: data,
})

export const deleteMessageFail = error => ({
  type: DELETE_MESSAGE_FAIL,
  payload: error,
})

