import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getFirebaseApp, getFirebaseBackend } from "helpers/firebase_helper";
import { getAuth } from "firebase/auth";
import { Container, Row, Spinner } from "reactstrap";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from "react-redux";
import { getProfile, loadUser } from "store/actions";
import { analytics } from "helpers/analytics_helper";
import { createSelector } from "reselect";
import { updateAffiliate } from "store/affliates/actions";



const AuthMiddleware = (props: any) => {

  const auth = getAuth(getFirebaseApp());
  const [authUser, loading, error] = useAuthState(auth);

  const location = useLocation();
  
  const dispatch = useDispatch();
  
  const {
    profile,
  } = useSelector(createSelector((state) => state.profile, 
        (profile: any) => ({profile: profile.profile})));

  const { affiliateUpdateSuccess } = useSelector(createSelector((state: any) => state.affiliates, (affiliates: any) => ({affiliates: affiliates.affiliates, affiliateUpdateSuccess: affiliates.success})));


  useEffect(() => {
    if (authUser) {
      getFirebaseBackend().updateAuthToken(authUser);
      dispatch(getProfile());
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser && profile) {
      // update the store populating the user claims 
      // TODO should take this out of profile call
      dispatch(loadUser({user: authUser, profile}));

      const referralCode = localStorage.getItem("affiliateRef");
      if (referralCode) {
        dispatch(updateAffiliate({ref: referralCode}));
      }

      if (!profile?.claims?.admin) {
        analytics.identify(authUser.uid, {
          name: authUser.displayName,
          email: authUser.email
        });
      }
    }
  }, [profile]);

  useEffect(() => {
    if (affiliateUpdateSuccess) {
      localStorage.removeItem("affiliateRef");
    }
  }, [affiliateUpdateSuccess]);
  

  if (loading) {
    return <Container>
      <Row className="justify-content-center position-absolute start-50 top-30">
        <Spinner/>
      </Row>
    </Container>
  }

  if (error) {
    return (
      <div>
        <p>Error: {error.message}</p>
      </div>
    );
  }

  if (!authUser) {
    return (
      <Navigate to="/login" state={ { redirectTo: location } } />
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default AuthMiddleware;
