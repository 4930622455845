
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, CardBody, CardTitle, Col, Nav, NavItem, NavLink, Progress, Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import profileImg from "../../assets/images/profile-img.png"
import Avatars from "components/Common/Avatar";
import { useEffect, useState } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { listBots } from "store/bot-trader/actions";
import CurrencyFormat from "components/Common/CurrencyFormat";
import RecommendedBrokers from "pages/Onboarding/RecommendedBrokers";
import classnames from "classnames";
import ReferralLink from "pages/Affiliates/ReferralLink";

const DashboardPage = () => {

    document.title = "Dashboard | Vertex Robot Trading";

    const dispatch = useDispatch();

    const [displayName, setDisplayName] = useState("");
    const [progress, setProgress] = useState(0);
    const [setupState, setSetupState] = useState("");
    const [botsBalance, setBotsBalance] = useState(0);
    const [viewAllBrokers, setViewAllBrokers] = useState(false);

    const { currentUser, loadingUser } = useSelector(createSelector((state: any) => state.login, (login: any) => ({currentUser: login.user, loadingUser: login.loading})));
    const { profile, loadingProfile } = useSelector(createSelector((state: any) => state.profile, (profile: any) => ({profile: profile.profile, loadingProfile: profile.loading})));

    useEffect(() => {

        if (!currentUser || !profile) {
            return;
        }
        setDisplayName(currentUser.displayName);
        if (profile.tasks) {
            setProgress(Math.round((profile.tasks.filter(t => t.isComplete).length / profile.tasks.length) * 100));
        }
        if (progress == 100) {
            setSetupState("TASKS_COMPLETE");
        } else if (profile.survey) {
            if (!profile.survey.isSurveyComplete) {
                setSetupState("SURVEY_IN_PROGRESS");
            } else if (profile.survey.isSurveyComplete) {
                setSetupState("SURVEY_COMPLETE");
            }
        } else {
            setSetupState("SURVEY_NOT_STARTED");
        }
    }, [currentUser, profile]);

    const BotsProperties = createSelector((state: any) => state.bots, (Bots) => ({
        bots: Bots.bots,
        loadingBots: Bots.loading
    }));
    const { bots, loadingBots } = useSelector(BotsProperties);
  
    useEffect(() => {
        dispatch(listBots());
    }, []);

    useEffect(() => {
        const totalBalance = bots.reduce((agg, cur) => (agg + Number(cur.tradeInfo?.balance || 0)), 0);
        setBotsBalance(totalBalance);
    }, [bots]);

    const onOpenAccountWith = (broker: any) => {
        
    }

    return (
        <div className="page-content">
            <Breadcrumbs title="Dashboard" />
            <Row>

                <Col>

                    <Card className="overflow-hidden">
                        <div className="bg-primary-subtle">
                            <Row>
                                <Col xs="7">
                                    <div className="text-primary p-3">
                                        <h5 className="text-primary">Welcome !</h5>
                                        <p>Robots Dashboard</p>
                                    </div>
                                </Col>
                                <Col xs="5" className="align-self-end">
                                    <img src={profileImg} alt="" className="img-fluid" />
                                </Col>
                            </Row>
                        </div>
                        <CardBody className="pt-0">
                            <Row>
                                <Col sm="6">
                                    <div className="avatar-md profile-user-wid mb-4">
                                        <Avatars size="80" name={currentUser?.displayName} photoUrl={currentUser?.photoURL}/>
                                    </div>
                                    <h5 className="font-size-15 text-truncate pt-2">{displayName}</h5>
                                    <p className="text-muted mb-0 text-truncate">{currentUser?.email}</p>
                                </Col>

                                <Col sm="6">
                                    <div className="pt-4">
                                        <Row>
                                            <Col xs="6">
                                                <h5 className="font-size-15">{loadingBots ? " - " : bots.length}</h5>
                                                <p className="text-muted mb-0">Robots</p>
                                            </Col>
                                            <Col xs="6">
                                                <h5 className="font-size-15">
                                                    { <CurrencyFormat isNull={() => loadingBots} value={botsBalance}/> }
                                                </h5>
                                                <p className="text-muted mb-0">Balance</p>
                                            </Col>
                                        </Row>
                                        <div className="mt-4">
                                            <Link
                                                to="/bots"
                                                className="btn btn-primary btn-sm">
                                                My Robots <i className="mdi mdi-arrow-right ms-1"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>


                    {<Card>
                        <CardBody>
                            <CardTitle className="mb-4">
                                <Link to="/affiliates">Become an Affiliate</Link>
                            </CardTitle>
                            <div className="card-title-desc">
                                Boost your income by spreading the word!
                                Earn a <strong><CurrencyFormat value={profile?.affiliate?.commissionAmount || 100} noTrailingZeros={true}/></strong> referral fee for every new customer you refer.
                            </div>
                            <div className="d-flex">
                                <h3>{ <CurrencyFormat value={profile?.affiliate?.earningsTotal || 0}/> }</h3>
                                <p className="ms-2 text-muted align-text-bottom">
                                    your affiliate earnings
                                </p>
                            </div>
                                <ReferralLink className="mt-3" shortId={profile?.shortId}/>

                        </CardBody>
                    </Card>}
                </Col>
                <Col>


                    <Card>
                        <CardBody>
                            <CardTitle className="h4">
                                <Link to="setup">Set Up Tasks</Link>
                            </CardTitle>
                            <div className="card-title-desc">
                                {
                                    loadingProfile ? 
                                        <div className="d-flex"><Spinner className="m-auto"/></div>
                                    : <>
                                        { setupState == "TASKS_COMPLETE" &&
                                            <span>All setup tasks are done <i color="success" className={"text-success fs-4 mdi mdi-check-bold"} /></span>} 

                                        { setupState == "SURVEY_IN_PROGRESS" &&
                                            <span>Please complete survey 
                                                <Link
                                                    to="/setup"
                                                    className="btn btn-primary btn-sm ms-3">
                                                    Continue <i className="mdi mdi-arrow-right ms-1"></i>
                                                </Link>
                                            </span>
                                        }
                                        { setupState == "SURVEY_NOT_STARTED" &&
                                            <span>Please answer a few question to get setup and select your broker
                                                <Link
                                                    to="/setup/go"
                                                    className="btn btn-primary btn-sm ms-3">
                                                    Let's Go <i className="mdi mdi-arrow-right ms-1"></i>
                                                </Link>
                                            </span>
                                        }
                                        { setupState == "SURVEY_COMPLETE" &&
                                            <span>Please complete setup tasks
                                                <Link
                                                    to="/setup"
                                                    className="btn btn-primary btn-sm ms-3">
                                                    Tasks <i className="mdi mdi-arrow-right ms-1"></i>
                                                </Link>
                                            </span>
                                        }
                                    </>
                                } 
                            </div>
                            <div >
                                <div className="animated-progess">
                                <Progress color="success" className="progress-xl fs-6" value={progress}>
                                    {progress}%
                                </Progress>
                                </div>
                            </div>
                        </CardBody>
                    </Card>


                    <Card>
                        <CardBody>
                            <CardTitle className="h4">
                                <Nav tabs className="nav-tabs-custom nav-justified">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                            active: !viewAllBrokers,
                                            })}
                                            onClick={() => {
                                                setViewAllBrokers(!viewAllBrokers);
                                            }}
                                        >
                                            Recommended Brokers
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                            active: viewAllBrokers,
                                            })}
                                            onClick={() => {
                                            setViewAllBrokers(!viewAllBrokers);
                                            }}
                                        >
                                            All Brokers
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                
                            </CardTitle>
                            <div className="card-title-desc">
                                {
                                    loadingProfile ? <div className="d-flex"><Spinner className="m-auto"/></div>
                                    : <RecommendedBrokers useLink={true} label={"Open an account with"} profile={profile} viewAll={viewAllBrokers}/>
                                } 
                            </div>
                        </CardBody>
                    </Card>
                    
                </Col>


            </Row>
            
        </div>
    )



}

DashboardPage.propTypes = {
    navigate: PropTypes.object,
};

export default withRouter(DashboardPage);


