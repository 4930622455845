import { 
  GET_RESULTS,
  GET_RESULTS_ERROR,
  GET_RESULTS_SUCCESS} from "./actionTypes"

export const getResults = () => {
  return {
    type: GET_RESULTS
  }
}

export const getResultsSuccess = (results) => {
  return {
    type: GET_RESULTS_SUCCESS,
    payload: results,
  }
}

export const getResultsError = error => {
  return {
    type: GET_RESULTS_ERROR,
    payload: error,
  }
}
