import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";



const ResultsPage = () => {
    const dispatch = useDispatch();



    return <>
    </>


}

export default ResultsPage;