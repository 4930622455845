export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";
export const GET_PROFILE_ADMIN = "GET_PROFILE_ADMIN";
export const GET_PROFILE_ADMIN_SUCCESS = "GET_PROFILE_ADMIN_SUCCESS";
export const GET_PROFILE_ADMIN_ERROR = "GET_PROFILE_ADMIN_ERROR";
export const UPDATE_PROFILE_ADMIN = "UPDATE_PROFILE_ADMIN";
export const UPDATE_PROFILE_ADMIN_SUCCESS = "UPDATE_PROFILE_ADMIN_SUCCESS";
export const UPDATE_PROFILE_ADMIN_ERROR = "UPDATE_PROFILE_ADMIN_ERROR";
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";
export const UPDATE_SUB = "UPDATE_SUB";
export const UPDATE_SUB_SUCCESS = "UPDATE_SUB_SUCCESS";
export const UPDATE_SUB_ERROR = "UPDATE_SUB_ERROR";
export const GET_SUB = "GET_SUB"
export const GET_SUB_SUCCESS = "GET_SUB_SUCCESS"
export const GET_SUB_ERROR = "GET_SUB_ERROR"