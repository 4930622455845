
import { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import ChatProfileImage from "./ChatProfileImage";
import ChatOnlineStatus from "./ChatOnlineStatus";
import ChatLastMessage from "./ChatLastMessage";
import classnames from "classnames";


const ChatAvatar = ({ channel, currentUser, isHeader, onSelectUser, onUpdateUser }: {channel: any, currentUser: any, isHeader: boolean, onSelectUser?: Function, onUpdateUser?: Function}) => {

    const [ members, setMembers ] = useState([]);


    useEffect(() => {
        if (!channel.id || !channel.contacts) {
            return;
        }
        const membersList = channel.contacts.filter(c => c.isVisible);
        setMembers(membersList);

        onUpdateUser && onUpdateUser(membersList[0]);
    }, [channel.id, channel.contacts]);

    return (
        <div className="d-flex p-1">
            {!isHeader && <div className="position-relative z-1 align-self-end me-3 p-0">
                {members.map((m, index) => 
                    <ChatProfileImage member={m} index={index} key={`av-${channel.id}-${m.uid}`}/>
                )}
            </div>}
            <div className="flex-grow-1 overflow-hidden align-self-center">
                {!isHeader && <h5 className="text-truncate font-size-14 mb-1">
                    {members.map(m => m.displayName).join(", ")}
                    {/* {currentUser.claims.admin && <span className="ms-1">[{channel.tag}]</span>} */}
                </h5>}
                {isHeader && <ul className="ms-2 list-group list-group-horizontal">
                    {members.map((m) => 
                        <li className="list-group-item text-truncate h5 border-0 font-size-14 mb-1" key={`an--${channel.id}-${m.uid}`}>
                            <ChatOnlineStatus member={m} isBottomRight={false}/>

                            {currentUser.claims.admin && 
                                <button
                                    className={classnames(
                                    "accordion-button",
                                    "fw-medium",
                                    // { collapsed: selectedUser?.id == m.id && showUserProfile }
                                    )}
                                    type="button"
                                    onClick={() => onSelectUser(m)}
                                    style={{ cursor: "pointer" }}
                                >
                                    {m.displayName} 
                                    <i className="mdi mdi-information-outline ms-1 font-size-16"/>
                            
                                </button>
                            }
                            {!currentUser.claims.admin && <span>{m.displayName}</span>}
                        </li>

                    )}
                    {currentUser.claims.admin && <li className="list-group-item align-self-center h5 border-0 font-size-14 mb-1" key={`an--${channel.id}-tag`}>[{channel.tag}]</li>}

                </ul>}
                        
                {!isHeader && channel.lastMessage && <p className="text-truncate mb-0">
                    <ChatLastMessage channel={channel} message={channel.lastMessage} currentUser={currentUser}/>
                </p>}
            </div>
            <div className="font-size-13 align-self-center">
                {(!isHeader && channel.newCount > 0 && !channel.lastActionIsCurrentOpenChannel) && 
                <Badge style={{minWidth: 25}} pill color="primary" className="p-2">
                    {channel.newCount}
                </Badge>
                }
                {(!isHeader && channel.lastAction?.indexOf("newMessage") >= 0 && channel.lastActionIsCurrentOpenChannel) &&
                    <span
                        className="position-relative me-5">
                        <i className="bx bx-bell bx-tada fs-4" />
                        <span className="position-absolute top-0 start-50 ms-1 mt-n1 translate-middle-y badge bg-primary rounded-pill">New</span>
                    </span>
                }
            </div>
        </div>
    );
    
}

export default ChatAvatar;