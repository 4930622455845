import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
//import { configureStore, Tuple } from "@reduxjs/toolkit";

import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

// const store = configureStore({
//     reducer: rootReducer,
//     middleware: getDefaultMiddleware =>
//       getDefaultMiddleware({
//           thunk: false,
//           serializableCheck: false,
//       }).concat(sagaMiddleware),
//     // middleware: sagaMiddleware
//     // middleware: () => new Tuple(sagaMiddleware),
//     // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
// });
sagaMiddleware.run(rootSaga);

export default store;
