import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { UPDATE_AFFILIATE, GET_AFFILIATES } from "./actionTypes"
import { updateAffiliateSuccess, updateAffiliateError, getAffiliatesSuccess, getAffiliatesError } from "./actions"

import {
  updateAffiliate, getAffiliates,
  referralClick
} from "helpers/user_profile_api";
import { toast } from "react-toastify";

function* onGetAffiliates() {
  try {
    const response = yield call(getAffiliates)
    yield put(getAffiliatesSuccess(response))      
  } catch (error) {
    yield put(getAffiliatesError(error))
  }
}

function* onUpdateAffiliate({payload: {affiliate, action}}) {
  try {
    const response = action == "clicked" ? yield call(referralClick, affiliate) : yield call(updateAffiliate, affiliate)
    yield put(updateAffiliateSuccess(response))     
    toast.success(`Referral successfully with code ${affiliate.ref}`); 
  } catch (error: any) {
    toast.error(`Referral not successfull: ${error.response.data.message}`); 
    yield put(updateAffiliateError(error))
  }
}

function* affiliatesSaga() {
  yield takeEvery(GET_AFFILIATES, onGetAffiliates);
  yield takeEvery(UPDATE_AFFILIATE, onUpdateAffiliate);
}

export default affiliatesSaga
