import { getAuth } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseApp } from "helpers/firebase_helper";
import { Navigate, useParams } from "react-router-dom";
import Spinner from "components/Common/Spinner";
import { useDispatch } from "react-redux";
import { referralClick } from "store/affliates/actions";

const AffiliateRefPage = () => {

    
    const auth = getAuth(getFirebaseApp());
    const [user, loading] = useAuthState(auth);
    const {referralCode} = useParams();
    const dispatch = useDispatch();


    if (loading) {
        return <Spinner />
    }
    const existingCode = localStorage.getItem("affiliateRef");
    dispatch(referralClick({ref: referralCode, ...(existingCode && {previousRef: existingCode})}));
    localStorage.setItem("affiliateRef", referralCode);
    return (
        <Navigate to={{ pathname: "/" }} />
    );
}
export default AffiliateRefPage;