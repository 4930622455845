import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Routes, Route } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
import { authProtectedRoutes, publicRoutes } from "./routes/Routes";
import AuthMiddleware from "./routes/AuthMiddleware";
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "./assets/scss/theme.scss";
import { getFirebaseBackend, initFirebaseBackend } from "./helpers/firebase_helper";
import { getChats, getMessages, recieveMessage, recieveUserStatus, resetNewCount } from "store/actions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// init firebase backend
initFirebaseBackend(firebaseConfig);


const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = (props) => {

  const dispatch = useDispatch();

  const selectLayoutState = (state: any) => state.Layout;
  const LayoutProperties = createSelector(
    selectLayoutState,
      (layout) => ({
        layoutType: layout.layoutType,
      })
  );
  const {
      layoutType
  } = useSelector(LayoutProperties);
  const Layout = getLayout(layoutType);

  const { currentUser } = useSelector(createSelector((state: any) => state.login, (login: any) => ({currentUser: login.user})));

  const selectChatState = (state) => state.chats;
  const ChatProperties = createSelector(
    selectChatState,
    (chats) => ({
      currentChannel: chats.currentChannel,
    })
  );
  const { currentChannel } = useSelector(ChatProperties);
  
  const setUpRealtimeData = () => {
    const unsubscripeUserStatus = getFirebaseBackend().onUserStatus((snapshot: any) => {
      if (snapshot.val() == false) {
        return;
      };
      const data = snapshot.val();
      dispatch(recieveUserStatus({uid: snapshot.key, ...data}));
    });

    const unsubscripeUserChannels = getFirebaseBackend().onRealtimeChildData("channel", (snapshot: any, prevKey: string) => {
      if (snapshot.val() == false) {
        return;
      };
      const data = snapshot.val();
      const channelId = snapshot.key;

      // console.log("recieved", data.action, channelId, data);

      dispatch(recieveMessage({channelId, ...data}));
      if (data.action.indexOf("newMessage") >= 0) {
        dispatch(resetNewCount(channelId));
      }
    });

    return () => {
      unsubscripeUserStatus();
      unsubscripeUserChannels();
    };
  }

  useEffect(() => {
    if (currentUser) {
      dispatch(getChats());
      return setUpRealtimeData();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentChannel?.id) {
        dispatch(getMessages(currentChannel.id));
    }
  }, [currentChannel]);
  

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthMiddleware>
                <Layout>{route.component}</Layout>
              </AuthMiddleware>}
            key={idx}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;

