import { getServerError } from "helpers/util"
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  LOAD_USER_SUCCESS,
  LOAD_USER,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
} from "./actionTypes"

export const loginUser = (user, navigate, location) => {
  return {
    type: LOGIN_USER,
    payload: { user, navigate, location },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const loadUser = (payload) => {
  return {
    type: LOAD_USER,
    payload,
  }
}

export const loadUserSuccess = payload => {
  return {
    type: LOAD_USER_SUCCESS,
    payload,
  }
}

export const logoutUser = navigate => {
  return {
    type: LOGOUT_USER,
    payload: { navigate },
  }
}

export const logoutUserSuccess = (data) => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: data,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: getServerError(error),
  }
}

export const socialLogin = (type, navigate, location) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { type, navigate, location },
  };
};

export const editUser = user => {
  return {
    type: EDIT_USER,
    payload: { user },
  }
}

export const editUserSuccess = msg => {
  return {
    type: EDIT_USER_SUCCESS,
    payload: msg,
  }
}
