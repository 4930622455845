import { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Row,
} from "reactstrap";
import Spinner from "components/Common/Spinner";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { listBots } from "store/bot-trader/actions";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { updateBotData } from '../../../store/bot-trader/actions'
import BotsTable from "./BotsTable";

const Bots = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectBotsState = (state: any) => state.bots;
  const BotsProperties = createSelector(
    selectBotsState,
    (bots) => ({
      bots: bots.bots,
      loading: bots.loading
    })
  );

  const {
    bots, loading
  } = useSelector(BotsProperties);

  const dispatchListBots = () => {
    dispatch(listBots());
  }

  useEffect(dispatchListBots, [dispatch]);

  const ChatProperties = createSelector(
    (state: any) => state.chats,
    (chats: any) => ({
      contacts: chats.contacts,
    })
  );
  const { contacts } = useSelector(ChatProperties);
  
  const { currentUser } = useSelector(createSelector((state: any) => state.login, (login: any) => ({currentUser: login.user})));


  const setUpRealtimeData = () => {
    const unsubscripeSummary = getFirebaseBackend().onRealtimeData("summary", (snapshot: any) => {
      if (snapshot.val() == false) {
        return;
      };
      const data = snapshot.val();
      for (const botId in data) {
        const tradeInfo = data[botId];
        dispatch(updateBotData({
          id: botId, tradeInfo
        }));
      }
    });

    const unsubscripeAccount = getFirebaseBackend().onRealtimeData("account", (snapshot: any) => {
      if (snapshot.val() == false) {
        return;
      };
      const data = snapshot.val();
      for (const botId in data) {
        const {state, loggedIn, errorCode, message} = data[botId];

        // if (errorCode == "LOGIN_FAILED") {
        //   toast.error("Login to broker failed, please check your broker login!", { autoClose: 15000 });
        // }

        dispatch(updateBotData({
          id: botId, 
          ...(state && {state}), 
          ...(loggedIn && {loggedIn}), 
          ...(errorCode && {errorCode}), 
          ...(message && {message})
        }));
      }
    });

    return () => {
      unsubscripeSummary();
      unsubscripeAccount();
    };
  }

  useEffect(setUpRealtimeData, []);

  return (
      <Container fluid>
        <Row>
          <Col className="text-end">
            {currentUser?.claims?.admin && <button
              type="button"
              className="btn btn-light mb-2 me-2"
              onClick={dispatchListBots}
            >
              <i className="mdi mdi-refresh d-block font-size-14"></i>
            </button>}
            <button
              type="button"
              className="btn btn-primary btn-label mb-2 w-sm"
              onClick={() => navigate(`/bots/new/choose`)}>
              <i className="bx bx-plus label-icon"></i> New Robot
            </button>
          </Col>
        </Row>
        {
          loading ? <Spinner/>
            :
            <>
              <Row>
                <BotsTable botsData={bots} contacts={contacts} currentUser={currentUser} />
              </Row>
            </>
        }
      </Container>
  );
};

Bots.propTypes = {
  bots: PropTypes.array,
  listBots: PropTypes.func,
};

export default Bots;
