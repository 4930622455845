import { get, post } from "./api_helper";

export const updateProfile = (data) => post("/profile", data);
export const getProfile = (userId?) => get(`/profile${userId ? "/" + userId : ""}`);
export const getPromotionId = (code) => get(`/stripe/promotion_id/${code}`);

export const updateSubs = (userId, data) => post(`/subs/${userId}`, data);
export const getSubs = (userId) => get(`/subs/${userId}`);


export const updateAffiliate = (data) => post("/affiliate", data);
export const referralClick = (data) => post("/referral/click", data);
export const getAffiliates = () => get(`/referrals`);


export const updateProfileAdmin = (userId, data) => post(`/admin/profile/update/${userId}`, data);