import React, { useState, useEffect } from "react"
import { Container, Row, Col, Spinner } from "reactstrap";
import { getProducts } from "../../ext-firestore-stripe-payments";
import { getPayments } from "../../helpers/firebase_helper";

//Import Breadcrumb
import Breadcrumbs, { DASHBOARD_ITEM } from "../../components/Common/Breadcrumb"

//Import Pricing Cards
import CardPricing from "../Utility/card-pricing"
import { toast } from "react-toastify";
import { isDownloadEnabled } from "helpers/config";

const PagesPricing = () => {

  //meta title
  document.title = "Pricing | Vertex Robot Trading";

  const [pricing, setPricing] = useState([]);
  const [loading, setLoading] = useState(true);

  const selectedCurrency = "USD";

  const defaultFeatures = [
    { title: "Includes future updates and improvements"},
    { title: "Requires sign-up with one of our affiliate Brokers" },
    { title: `Runs in the cloud 24/7 <strong>for free</strong>${isDownloadEnabled ? " or Download" : ""}` },
    { title: "Easy/Fast fully automated setup" },
  ];

  const freeTrail = {
      id: "free-trial",
      title: "Free Trial",
      description: "2 weeks try before you buy",
      // icon: "bx-walk",
      price: "0",
      currency: "",
      duration: "Free for 2 weeks",
      link: "/setup",
      button: "Try now",
      features: [
        { title: "Try any Vertex Robot" },
        ...defaultFeatures
      ],
  };



  useEffect(() => {
    const payments = getPayments()

    if (!payments) {
      toast("Error retrieving product information");
      return;
    }

    getProducts(payments, {
      includePrices: true,
      activeOnly: true,
    }).then(products => {
      freeTrail.currency = selectedCurrency;
      const pricingData = [];
      pricingData.push(freeTrail);
      for (const product of products.sort(p => (p?.prices[0].unit_amount))) {
        for (const price of product?.prices.filter(p => p.active)) {
          if (price.currency.toUpperCase() == selectedCurrency) {
            const features = [];
            for (const key in product.metadata) {
              if (key.startsWith("feature")) {
                features.push({title: product.metadata[key].toString()});
              }
            }
            defaultFeatures.forEach(feature => features.push(feature));

            pricingData.push({
              id: product.id,
              title: product.metadata?.title,
              description: product.description,
              images: product.images,
              // icon: "bx-run",
              price: price.unit_amount / 100,
              currency: price.currency,
              productId: product.id,
              priceId: price.id,
              duration: price.type == "one_time" ? "Single payment" : price.interval,
              type: price.type,
              features
            });
          }
        }
      }
      setPricing(pricingData);
      setLoading(false);
  })}, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <Breadcrumbs title="Pricing" items={[DASHBOARD_ITEM]} />

          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center mb-5">
                <h4>Choose your Robot</h4>
                <p className="text-muted">
                  24/7 Support with all Vertex Robots purchases
                </p>
              </div>
            </Col>
          </Row>
          <Row>
          { loading ? <Col className="text-center mt-5"><Spinner/></Col>: 
              <>            
              {pricing.map((pricing, key) => (
                <CardPricing pricing={pricing} key={"_pricing_" + key} />
              ))}
              </>
          }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default PagesPricing;

