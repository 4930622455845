import { useEffect, useState } from "react";


const ChatSenderName = ({channel, message, currentUser}) => {

    const [name, setName] = useState("");


    useEffect(() => {
        if (!message) {
            return;
        }
        if (message.senderId == currentUser.uid) {
            setName("You");
        } else {
            const senderId = (message.senderId || channel.id);
            setName(channel.contacts?.find(c => c.uid == senderId)?.displayName || "???");
        }
    }, [message]);

    return <>
        {name}
    </>

}

export default ChatSenderName;