import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Avatars from 'components/Common/Avatar';

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { createSelector } from "reselect";

const ProfileMenu = props => {
  
  const { currentUser } = useSelector(createSelector((state: any) => state.login, (login: any) => ({currentUser: login.user})));


  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >

        <Avatars size="50" name={currentUser?.displayName} photoUrl={currentUser?.photoURL}/>

        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" onClick={() => setMenu(false)} className="dropdown-item">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </Link>
          <Link to="/affiliates" onClick={() => setMenu(false)} className="dropdown-item">
            <i className="bx bx-link font-size-16 align-middle me-1" />
            Affiliate Area
          </Link>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
