import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { map } from "lodash";
import {
  Badge,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";

import Breadcrumbs, { DASHBOARD_ITEM } from "components/Common/Breadcrumb";

import SimpleBar from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';

import { isChatOpen, resetNewCount, selectChannel } from "store/actions";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";

import ChatMessages from "./ChatMessages";
import ChatAvatar from "./ChatAvatar";
import classnames from "classnames";

const Chat = () => {

  document.title = "Chat | Vertex Robot Tading";

  const dispatch = useDispatch();

  const { channelId } = useParams();
  const { currentUser } = useSelector(createSelector((state: any) => state.login, (login: any) => ({currentUser: login.user})));

  const selectChatState = (state) => state.chats;
  const ChatProperties = createSelector(
    selectChatState,
    (chats) => ({
      chats: chats.chats,
      currentChannel: chats.currentChannel,
      loading: chats.loadingContacts || chats.loadingChannels
    })
  );
  const { chats, currentChannel, loading } = useSelector(ChatProperties);
  const [ channelsOrdered, setChannelsOrdered ] = useState([]);
  const [ selectedTag, setSelectedTag ] = useState(currentChannel.tag);
  const [ newCountByTags, setNewCountByTags] = useState({});

  const TAGS = [{name: "Sales", id: "sales"}, {name: "Support", id: "support"}];
  const STATUS_COMPARE = {"ON-LINE": "0", "OFF-LINE": "1", "SIGNED-OUT": "1"};
  useEffect(() => {

    let hasAllContacts = true;
    setChannelsOrdered([]);
    let list = currentUser?.claims?.admin ? chats.filter(a => a.tag == selectedTag) : chats;
    list = list.sort((a, b) => {
      if (!a.contacts || !b.contacts) {
        hasAllContacts = false;
        return 0;
      }
      const cA = a.contacts.filter(c => c.isVisible).at(-1);
      const cB = b.contacts.filter(c => c.isVisible).at(-1);
      if (!a.lastMessage && !b.lastMessage) {
        return STATUS_COMPARE[cA.status].concat(cA.displayName)
                    .localeCompare(STATUS_COMPARE[cB.status].concat(cB.displayName));
      }
      return (a.lastMessage && b.lastMessage ? b.lastMessage?.sentAt?.localeCompare(a.lastMessage?.sentAt) : (a.lastMessage ? -1 : 1));
    });

    if (hasAllContacts) {
      setChannelsOrdered(list);
    }
  }, [chats, selectedTag]);

  useEffect(() => {
    let tag = currentChannel.tag || "sales";
    if (currentUser?.claims?.admin && !currentChannel.tag) {
      const lastMessageChat = chats.reduce((agg, value) => {
        if (value?.lastMessage?.sentAt) {
          if (agg?.lastMessage?.sentAt) {
            return agg.lastMessage.sentAt.localeCompare(value.lastMessage.sentAt) > 0 ? 
                      agg : value;
          }
          return value;
        }
        return agg;
      }, null);
      if (lastMessageChat) {
        tag = lastMessageChat.tag;
      }
    }
    setSelectedTag(tag);
  }, [currentChannel]);

  useEffect(() => {

    setNewCountByTags(chats.reduce((agg, chat) => {
      agg[chat.tag] = (agg[chat.tag] || 0) + chat.newCount;
      return agg;
    }, {}));

    if (channelId && currentChannel.id != channelId) {
      const openChannel = chats.find(c => [c.tag, c.id].indexOf(channelId) >= 0);
      if (openChannel) {
        dispatch(selectChannel(openChannel));
      } 
    }

  }, [chats, channelId]);

  useEffect(() => {

    dispatch(isChatOpen(true));

    if ((!channelId || currentChannel.id == channelId) 
        && currentChannel.id && currentChannel.newCount > 0) {
      dispatch(resetNewCount(currentChannel.id));
    }

    return () => {
      dispatch(isChatOpen(false));
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <Breadcrumbs title="Chat" items={[DASHBOARD_ITEM]} />

          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar">
                  <div >

                    <div className="chat-leftsidebar-nav position-relative">
                 
                    {currentUser?.claims?.admin && <Nav pills justified>
                        {TAGS.map(tag => 
                        <NavItem key={`tagNav-${tag.id}`}>
                          <NavLink
                            className={classnames({
                              active: selectedTag == tag.id,
                              "d-flex justify-content-around": true
                            })}
                            onClick={() => {
                              setSelectedTag(tag.id);
                            }}
                          >
                            <span className="xd-none xd-sm-block pt-1">{tag.name}</span>
                            {newCountByTags[tag.id] > 0 && <Badge className="xfloat-end p-2" style={{minWidth: 25}} pill color={selectedTag == tag.id ? "light" : "primary"}>
                              {newCountByTags[tag.id]}
                            </Badge>}
                          </NavLink>
                        </NavItem>
                        )}
                        
                      </Nav>}
                      
                      <div>
                        <h5 className="font-size-14 mb-3"></h5>
                        <ul className="list-unstyled chat-list" id="recent-list">
                          {
                            loading ? <Spinners /> :
                              <SimpleBar className="pe-4" forceVisible="y" autoHide={true} style={{ maxHeight: "690px" }}>
                              {/* <SimpleBar> */}
                                {map(channelsOrdered, channel => (
                                  <li
                                    key={channel.id + channel.status}
                                    className={ 
                                      currentChannel.id === channel.id
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <Link className="p-0" to={`/chat/${channel.id}`}>
                                      <ChatAvatar channel={channel} currentUser={currentUser} isHeader={false}/>
                                    </Link>
                                  </li>
                                ))}
                              </SimpleBar>
                          }
                        </ul>
                      </div>

                            
                    </div>
                  </div>
                </div>
                <ChatMessages channel={currentChannel} currentUser={currentUser} />
              </div>
            </Col>
          </Row >
        </Container >
      </div >
    </React.Fragment >
  );
};

Chat.propTypes = {
  chats: PropTypes.array,
  onGetChats: PropTypes.func,
};

export default Chat;
