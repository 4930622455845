import EmojiPicker from "emoji-picker-react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, Row, UncontrolledAlert, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import Spinners from "components/Common/Spinner";
import { handleSearchData } from "components/Common/searchFile";

import {
    deleteMessage as onDeleteMessage,
    addMessage as onAddMessage,
    recieveMessage as onRecievedMessage,
    getMessages as onGetMessages,
} from "store/actions";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import ChatAvatar from "./ChatAvatar";
import moment from "moment";
import { getLocale } from "helpers/util";
import Spinner from "components/Common/Spinner";
import ChatSenderName from "./ChatSenderName";
import classNames from "classnames";
import AdminUserProfileView from "../User/AdminUserProfileView";

const ChatMessages = ({ channel, currentUser }: {channel: any, currentUser: any}) => {

    const dispatch = useDispatch();

    const selectChatState = (state) => state.chats;
    const ChatMessagesProperties = createSelector(
      selectChatState,
      (chats) => ({
        messages: chats.messages,
        loading: chats.loadingContacts || chats.loadingChannels || chats.loadingMessages,
        sendingMessage: chats.sendingMessage
      })
    );

    const { messages, loading, sendingMessage } = useSelector(ChatMessagesProperties);
    
    const [messagesData, setMessagesData] = useState([]);

    const [searchMenu, setSearchMenu] = useState(false);
    const [settingsMenu, setSettingsMenu] = useState(false);
    const [otherMenu, setOtherMenu] = useState(false);
    const [emoji, setEmoji] = useState(false);
    const [curMessage, setCurMessage] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [disable, setDisable] = useState(true);
    const scroollRef = useRef(null);
    const [ showUserProfile, setShowUserProfile ] = useState(false);
    const [ selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        setMessagesData(messages.sort((a, b) => a.sentAt.localeCompare(b.sentAt)));
    }, [onDeleteMessage, onRecievedMessage, messages]);


    useEffect(() => {
        if (scroollRef.current) {
            const el = scroollRef.current.getScrollElement();
            // el.scrollTop = el.scrollHeight;
            el.scrollTo(0, el.scrollHeight);
        }
    }, [messagesData]);

    const toggleShowUserProfile = () => {
        setShowUserProfile(!showUserProfile);
    }
  
    const onShowUserInfo = (user: any) => {
        setSelectedUser(user);
        toggleShowUserProfile();
    }

    const onUpdateUserInfo = (user: any) => {
        setSelectedUser(user);
    }

    const formatDateTime = (message) => {
        const date = moment(new Date(message.createdAt)).locale(getLocale());
        const nowSplit = moment().locale(getLocale()).format("YYYY/w/DD").split("/");
        const dateSplit = date.format("YYYY/w/DD").split("/");
        if (nowSplit[0] != dateSplit[0]) {
            return date.format("ll LT");
        }
        if (nowSplit[1] != dateSplit[1]) {
            return date.format("MMM DD, LT");
        }
        if (nowSplit[2] != dateSplit[2]) {
            return date.format("ddd LT");
        }
        return date.format("LT");
    }

    const toggleSearch = () => {
        setSearchMenu(!searchMenu);
    };

    const toggleSettings = () => {
        setSettingsMenu(!settingsMenu);
    };

    const toggleOther = () => {
        setOtherMenu(!otherMenu);
    };

    const handeleSearch = (ele) => {
        handleSearchData({ setState: setMessagesData, data: messages, item: ele.value })
    }

    const addMessage = () => {
        if (curMessage?.trim().length > 0 || selectedImage !== null) {
            const newMessage = {
                isNew: true,
                channelId: channel.id,
                senderId: currentUser.uid,
                text: curMessage?.trim(),
                ...(selectedImage && {images: [selectedImage]}),
                sentAt: new Date().toISOString(),
            };
            dispatch(onAddMessage(newMessage));
            setCurMessage("");
            setDisable(false)
            setEmoji(false);
            setSelectedImage(null)
        }
    };


    const onKeyPress = e => {
        const { key, value } = e;
        if (key === "Enter" && !sendingMessage) {
            setCurMessage(value);
            setDisable(true);
            addMessage();
        }
    };

    const deleMsg = (message) => {
        if (message.senderId == currentUser.uid) {
            dispatch(onDeleteMessage({channelId: channel.id, messageId: message.id}))
        }
    };

    const [copyMsgAlert, setCopyMsgAlert] = useState(false);
    const copyMsg = (ele) => {
        var copyText = ele.closest(".conversation-list").querySelector("p").innerHTML;
        navigator.clipboard.writeText(copyText);
        setCopyMsgAlert(true)
        if (copyText) {
            setTimeout(() => {
                setCopyMsgAlert(false)
            }, 1000)

        }
    };

    // emoji
    const [emojiArray, setEmojiArray] = useState([]);
    const onEmojiClick = (event, emojiObject) => {
        setEmojiArray([...emojiArray, emojiObject.emoji]);
        setCurMessage(curMessage + event.emoji);
        setDisable(true)
    };

    //  img upload
    const handleImageChange = (event) => {
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = () => {
            setSelectedImage(reader.result);
            setDisable(true)
        };
        reader.readAsDataURL(file);
    };

    return (

        <div className="w-100 user-chat">
            {(currentUser?.claims?.admin) && <AdminUserProfileView user={selectedUser} show={showUserProfile} toggleOpen={toggleShowUserProfile}/>}
          
          
            {channel?.id && <Card>
                <div className="border-bottom">
                    <Row>
                        <Col md="6" xs="9" className="d-flex align-items-center">
                            <ChatAvatar channel={channel} currentUser={currentUser} isHeader={true} onSelectUser={onShowUserInfo} onUpdateUser={onUpdateUserInfo}/>
                        </Col>
                        <Col className="p-4" md="6" xs="3">
                            <ul className="list-inline user-chat-nav text-end mb-0">
                                <li className="list-inline-item d-none d-sm-inline-block">
                                    <Dropdown
                                        className="me-1"
                                        isOpen={searchMenu}
                                        toggle={toggleSearch}
                                    >
                                        <DropdownToggle className="btn nav-btn" tag="a">
                                            <i className="bx bx-search-alt-2" />
                                        </DropdownToggle>
                                        <DropdownMenu
                                            className="dropdown-menu-md"
                                        >
                                            <Form className="p-3">
                                                <FormGroup className="m-0">
                                                    <InputGroup>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search ..."
                                                            aria-label="Recipient's username"
                                                            onChange={(e) => handeleSearch(e.target)}
                                                        />
                                                        {/* <InputGroupAddon addonType="append"> */}
                                                        <Button color="primary" type="submit">
                                                            <i className="mdi mdi-magnify" />
                                                        </Button>
                                                        {/* </InputGroupAddon> */}
                                                    </InputGroup>
                                                </FormGroup>
                                            </Form>
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>
                                {/* <li className="list-inline-item d-none d-sm-inline-block">
                                    <Dropdown
                                        isOpen={settingsMenu}
                                        toggle={toggleSettings}
                                        className="me-1"
                                    >
                                        <DropdownToggle className="btn nav-btn" tag="a">
                                            <i className="bx bx-cog" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#">
                                                View Profile
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                Clear chat
                                            </DropdownItem>
                                            <DropdownItem href="#">Muted</DropdownItem>
                                            <DropdownItem href="#">Delete</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>
                                <li className="list-inline-item">
                                    <Dropdown
                                        isOpen={otherMenu}
                                        toggle={toggleOther}
                                    >
                                        <DropdownToggle className="btn nav-btn" tag="a">
                                            <i className="bx bx-dots-horizontal-rounded" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem href="#">Action</DropdownItem>
                                            <DropdownItem href="#">
                                                Another Action
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                                Something else
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>

                <div>

                    <div className="chat-conversation">

                        <SimpleBar ref={scroollRef} style={{ height: "486px" }}>
                            {loading ? <Spinners /> :
                                <ul className="list-unstyled mb-0">
                                    { messagesData.map((message, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className={classNames({
                                                        "right": message.senderId === currentUser.uid,
                                                        "last-chat": message.id == channel.lastRead
                                                    })}
                                                >
                                                    <div className="conversation-list px-3">
                                                        <div className="conversation-name">
                                                            <ChatSenderName channel={channel} message={message} currentUser={currentUser}/>
                                                        </div>
                                                        <UncontrolledDropdown className="chat-hover-show">
                                                            <DropdownToggle
                                                                href="#!"
                                                                tag="a" className="dropdown-toggle"
                                                            >
                                                                <i className="bx bx-dots-vertical-rounded" />
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem onClick={(e) => copyMsg(e.target)} href="#">
                                                                    Copy
                                                                </DropdownItem>
                                                                <DropdownItem disabled={message.senderId != currentUser.uid} onClick={(e) => deleMsg(message)} href="#">
                                                                    Delete
                                                                </DropdownItem>

                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>

                                                        <div className="ctext-wrap">
                                                            <p className="fs-5" dangerouslySetInnerHTML={{__html: message.text}}/>
                                                            {message.images && <img src={message.images} alt="" width="150px" />}
                                                            {message.isNew && <Spinner isButton={true}/>}
                                                            {message.createdAt && <p className="chat-time mb-0 fw-lighter">
                                                                {/* <i className="bx bx-time-five align-middle me-1"></i> */}
                                                                {formatDateTime(message)}
                                                            </p>}
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                            
                                        })
                                    }
                                </ul>}
                        </SimpleBar>
                    </div>
                    {
                        selectedImage &&
                        <div className="replymessage-block mb-0 d-flex align-items-start">
                            <div className="flex-grow-1">
                                <img src={selectedImage} alt="select img" style={{ width: "150px", height: "auto" }} />
                            </div>
                            <div className="flex-shrink-0">
                                <button type="button" id="close_toggle" className="btn btn-sm btn-link mt-n2 me-n3 fs-18" onClick={() => setSelectedImage(null)}>
                                    <i className="bx bx-x align-middle"></i>
                                </button>
                            </div>
                        </div>
                    }

                    {copyMsgAlert && <UncontrolledAlert color='warning' dismissible role="alert">  Message copied</UncontrolledAlert>}
                    {emoji && <EmojiPicker onEmojiClick={onEmojiClick} width={250} height={382} />}
                    <div className="p-3 chat-input-section">
                        <Row>
                            <Col>
                                <div className="position-relative">
                                    <input
                                        type="text"
                                        value={curMessage}
                                        onKeyPress={onKeyPress}
                                        onChange={e => { setCurMessage(e.target.value); setDisable(true) }}
                                        className="form-control chat-input"
                                        placeholder={sendingMessage ? "Sending..." : "Enter Message..."}
                                    />
                                    <div className="chat-input-links">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item" onClick={() => setEmoji(!emoji)}>
                                                <Link to="#">
                                                    <i className="mdi mdi-emoticon-happy-outline me-1" id="Emojitooltip" />
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="Emojitooltip"
                                                    >
                                                        Emojis
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li>
                                            <li className="list-inline-item">
                                                <label htmlFor="imageInput">
                                                    <i className="mdi mdi-file-image-outline me-1" id="Imagetooltip" />
                                                    <UncontrolledTooltip placement="top" target="Imagetooltip">
                                                        Images
                                                    </UncontrolledTooltip>
                                                </label>
                                                <input type="file" id="imageInput" className="d-none" onChange={handleImageChange} />
                                            </li>
                                            {/* <li className="list-inline-item">
                                                <Link to="#">
                                                    <i className="mdi mdi-file-document-outline" id="Filetooltip" />
                                                    <UncontrolledTooltip placement="top" target="Filetooltip">
                                                        Add Files
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-auto">
                                <Button
                                    type="button"
                                    color="primary"
                                    disabled={!disable || sendingMessage}
                                    onClick={() => addMessage()}
                                    className="btn btn-primary btn-rounded chat-send w-md "
                                >
                                    <span className="d-none d-sm-inline-block me-2">
                                        {sendingMessage ? "Sending" : "Send" }
                                    </span>{" "}
                                    <i className="mdi mdi-send" />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Card>}
        </div>

    )
}

// ChatMessages.propTypes = {
//     channel: PropTypes.object, 
//     currentUser: PropTypes.object
//   };

export default ChatMessages;
