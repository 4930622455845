import { 
  UPDATE_AFFILIATE_ERROR, 
  UPDATE_AFFILIATE_SUCCESS, 
  UPDATE_AFFILIATE, 
  GET_AFFILIATES_SUCCESS,
  GET_AFFILIATES,
  GET_AFFILIATES_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  success: false,
  loading: true,
  affiliates: [],
};

const affiliates = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AFFILIATE:
      state = { ...state, success: false };
      break;
    case GET_AFFILIATES:
      state = { ...state, loading: true };
      break;
    case UPDATE_AFFILIATE_SUCCESS:
      state = { ...state, loading: false, success: true };
      break;
    case UPDATE_AFFILIATE_ERROR:
      state = { ...state, error: action.payload, loading: false, success: false };
      break;
    case GET_AFFILIATES_SUCCESS:
      state = { ...state, affiliates: action.payload, loading: false };
      break;
    case GET_AFFILIATES_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
  }
  return state;
};

export default affiliates;
