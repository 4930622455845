
import Spinner from "components/Common/Spinner";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Modal } from "reactstrap";
import { createSelector } from "reselect";
import { updateProfileAdmin } from "store/actions";

const UpdateProfileModal = ({open, setOpen, profile}) => {

    const dispatch = useDispatch();

    const { error, loading } = useSelector(createSelector((state: any) => state.profile, (profile: any) => ({error: profile.error, loading: profile.loading})));


    const [trailNumDays, setTrailNumDays] = useState("");
    const [purchased, setPurhased] = useState("");

    const toggleOpen = () => {
        setOpen(!open);
    }

    useEffect(() => {
        if (!profile) {
            return;
        }
        setPurhased((profile.purchased || []).join(", "));
    }, [profile]);

    const onUpdate = () => {
        const data = {
            ...(trailNumDays && Number.parseInt(trailNumDays) && {numDays: trailNumDays}),
            purchased
        };
        if (Object.keys(data).length > 0) {
            dispatch(updateProfileAdmin(profile.id, data));
        } 
    }

    return (
        <Modal
            isOpen={open}
            toggle={() => {
                toggleOpen()
            }}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Update User Profile</h5>
                <button type="button"
                    onClick={() => {
                        setOpen(false);
                    }} className="btn-close"></button>
            </div>
            <div className="modal-body">
                {error && 
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                    {error}
                    </Alert>
                }
                <form>
                    <div className="mb-3">
                        <label htmlFor="form-days-left" className="col-form-label">Free trail days left</label>
                        <input type="text" placeholder="-1 to clear" className="form-control" name="days-left" id="form-days-left" onChange={e => setTrailNumDays(e.target.value)} value={trailNumDays} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="form-purchased" className="col-form-label">Purchased</label>
                        <div className="input-group auth-pass-inputgroup">
                            <input type="text" className="form-control" name="purchased" id="form-purchased" onChange={e => setPurhased(e.target.value)} value={purchased} />
                        </div>
                        <div>Values: all-vertex-standard</div>
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button onClick={() => setOpen(false)} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button onClick={() => onUpdate()} type="button" className="btn btn-primary">Update {loading && <Spinner isButton={true}/>}</button>
            </div>
        </Modal>
    )
}

export default UpdateProfileModal;