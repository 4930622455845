import Spinner from "components/Common/Spinner";
import { Button, InputGroup, Label } from "reactstrap"

interface ReferralLinkProps {
    shortId: string,
    className?: any,
    showLabel?: boolean
}
const ReferralLink = ({className, shortId, showLabel = true}: ReferralLinkProps) => {

    if (!shortId) {
        return <Spinner isButton={true}/>
    }

    const referalLink = `https://app.vertexrobots.com/ref/${shortId}`;
        
    return (
        <InputGroup className={className} style={{flexWrap: "nowrap"}}>
            {showLabel && <Label className="input-group-text bg-primary text-white">Your Link</Label>}

            <Label className="input-group-text text-truncate">
                {referalLink}
            </Label>
            <Button color="primary" onClick={() => {navigator.clipboard.writeText(referalLink)}}>
                <i className="mdi mdi-content-copy ms-1"></i>
            </Button>
        </InputGroup>
    )
}

export default ReferralLink;