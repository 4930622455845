import { 
  UPDATE_AFFILIATE_ERROR, 
  UPDATE_AFFILIATE_SUCCESS, 
  UPDATE_AFFILIATE, 
  GET_AFFILIATES,
  GET_AFFILIATES_ERROR,
  GET_AFFILIATES_SUCCESS} from "./actionTypes"

export const updateAffiliate = affiliate => {
  return {
    type: UPDATE_AFFILIATE,
    payload: { affiliate },
  }
}

export const referralClick = affiliate => {
  return {
    type: UPDATE_AFFILIATE,
    payload: { affiliate, action: "clicked" },
  }
}

export const updateAffiliateSuccess = msg => {
  return {
    type: UPDATE_AFFILIATE_SUCCESS,
    payload: msg,
  }
}

export const updateAffiliateError = error => {
  return {
    type: UPDATE_AFFILIATE_ERROR,
    payload: error,
  }
}

export const getAffiliates = () => {
  return {
    type: GET_AFFILIATES
  }
}

export const getAffiliatesSuccess = (profile) => {
  return {
    type: GET_AFFILIATES_SUCCESS,
    payload: profile,
  }
}

export const getAffiliatesError = error => {
  return {
    type: GET_AFFILIATES_ERROR,
    payload: error,
  }
}
